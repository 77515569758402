import React, { useCallback, useState } from 'react';

import DefaultPage from './pages/Default';

const App = (props: {}) => {
    return (
      <DefaultPage />
    );
};

export default App;
