import React from 'react';

const pageStyle = {
  background: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  width: '100%',
};

const logoStyle = {
  width: '100%',
  height: 'auto',
};
const titleStyle = {
  fontFamily: '\'Roboto\', sans-serif',
  fontWeight: 'normal',
  color: 'white',
  boxSizing: 'border-box',
  height: '64px',
  textAlign: 'center',
}
const paragraphStyle = {
  fontFamily: '\'Roboto\', sans-serif',
  fontWeight: 'normal',
  color: '#777',
  textAlign: 'center',
};

const DefaultPage = () => {
  return (
    <div style={pageStyle}>
      <div style={wrapperStyle}>
        <img style={logoStyle} src="/images/logo.png" alt="Mihok Foundation logo" />
        <h1 style={titleStyle}>Mihok Foundation</h1>
        <p style={paragraphStyle}>Copyright &copy;  {(new Date()).getFullYear()} Mihok Foundation</p> 
      </div>
    </div>
  );
};

export default DefaultPage;
